import { HostType } from './theme'

type Period = 30 | 90 | 180 | 360

export type Plan = {
  description: string
  features: Record<string, string[]>
  mall?: string
  mallLogos: string[]
  theme: {
    bg: string
    fg: string
    glow: string
  }
  featured?: boolean
  pricing: Record<Period, { price: number; discountedPrice: number }>
}

export const plansByHost: Partial<
  Record<
    HostType,
    {
      currency: string
      plans: Plan[]
    }
  >
> = {
  sellmonitor: {
    currency: 'RUB',
    plans: [
      {
        mall: 'ozon',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/ozon_big.png'],
        description: '',
        features: {
          ru: [
            'Сервис-партнёр Ozon — официальные данные по внешней аналитике',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Поиск рекламы в соцсетях',
            'Управление ценами',
            'Автоответы на отзывы',
            'Расчёт поставок по складам',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 13990, discountedPrice: 13990 },
          90: { price: 41970, discountedPrice: 37770 },
          180: { price: 83940, discountedPrice: 58740 },
          360: { price: 167880, discountedPrice: 92280 },
        },
      },
      {
        mall: 'wb',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          ru: [
            'Авторизованный сервис Wildberries',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Поиск рекламы в соцсетях',
            'Управление рекламными кампаниями',
            'Управление ценами',
            'Расчёт поставок по складам с учётом географии заказов',
            'Автобронирование слотов поставок',
            'Автоответы на отзывы',
            'SEO-оптимизация и генерация описания с использованием ИИ',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 13990, discountedPrice: 13990 },
          90: { price: 41970, discountedPrice: 37770 },
          180: { price: 83940, discountedPrice: 58740 },
          360: { price: 167880, discountedPrice: 92280 },
        },
      },
      {
        mall: 'beru',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/beru_white_big.png'],
        description: '',
        features: {
          ru: [
            'Сервис-партнёр Яндекс Маркета',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 9990, discountedPrice: 9990 },
          90: { price: 29970, discountedPrice: 26970 },
          180: { price: 59940, discountedPrice: 41940 },
          360: { price: 119880, discountedPrice: 65880 },
        },
      },
    ],
  },
  uzum: {
    currency: 'UZS',
    plans: [
      {
        mall: 'wb',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          uz: [
            'Uzum Market bilan paket sifatida faqat sotib olinadi',
            'Wildberries rasmiy xizmati',
            'API asosidagi sotuvchi paneli',
            "Mahsulotlar, kategoriyalar, do'konlar va brendlar bo'yicha tashqi tahlil",
            "Qidiruv so'rovlari tahlili",
            "Avtoreklama bo'yicha stavkalarni boshqarish",
            'Sharhlarga avtomatik javob berish',
            'Buyurtmalar geografiyasini hisobga olgan holda omborlarga yetkazib berish hisobi',
            "SEO optimallashtirish va sun'iy intellekt yordamida tavsif yaratish",
            "Tahlil davri va chiqarilgan mahsulotlar soni bo'yicha cheklovlarsiz",
            'Cheklanmagan Excel chiqarishlari',
          ],
          ru: [
            'Доступен только в пакете с Uzum Market',
            'Авторизованный сервис Wildberries',
            'Личный кабинет продавца на основе API',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'Анализ поисковых запросов',
            'Управление ставками по авторекламе',
            'Автоответы на отзывы',
            'Расчёт поставок по складам с учётом географии заказов',
            'SEO-оптимизация и генерация описания с использованием ИИ',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 1790000, discountedPrice: 1790000 },
          90: { price: 5370000, discountedPrice: 4832970 },
          180: { price: 10740000, discountedPrice: 7517940 },
          360: { price: 21480000, discountedPrice: 11814000 },
        },
      },
      {
        mall: 'uzum',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/uzum_big.png'],
        description: '',
        features: {
          uz: [
            'Uzum Market rasmiy sherigi',
            "Mahsulotlar, kategoriyalar, do'konlar va brendlar bo'yicha tashqi tahlil",
            "Sun'iy intellekt yordamida tavsif yaratish",
            "Tahlil davri va chiqarilgan mahsulotlar soni bo'yicha cheklovlarsiz",
            'Cheklanmagan Excel chiqarishlari',
          ],
          ru: [
            'Официальный партнер Uzum Market',
            'Внешняя аналитика товаров, категорий, магазинов и брендов',
            'ИИ-генерация описания',
            'Без ограничений по периоду анализа и числу товаров в выдаче',
            'Неограниченные выгрузки в Excel',
          ],
        },
        pricing: {
          30: { price: 633000, discountedPrice: 633000 },
          90: { price: 1899000, discountedPrice: 1709070 },
          180: { price: 3798000, discountedPrice: 2658540 },
          360: { price: 7596000, discountedPrice: 4177680 },
        },
      },
    ],
  },
  sellscreen: {
    currency: 'CNY',
    plans: [
      {
        mall: 'ozon',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/ozon_big.png'],
        description: '',
        features: {
          en: [
            'Official Ozon partner — competitor analytics data direct from marketplace',
            'API-based seller dashboard',
            'Product, category, store and brand analytics',
            'Search query analytics',
            'Review autoreplier',
            'AI description generation',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            'Ozon官方合作伙伴 — 来自市场的竞争对手分析数据',
            '基于API的卖家仪表板',
            '产品、类别、商店和品牌分析',
            '搜索查询分析',
            '评论自动回复',
            '人工智能内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 668, discountedPrice: 668 },
          90: { price: 2004, discountedPrice: 1674 },
          180: { price: 4008, discountedPrice: 2988 },
          360: { price: 8016, discountedPrice: 4776 },
        },
      },
      {
        mall: 'wb',
        theme: {
          bg: 'bg-gray-900 lg:order-none order-first',
          fg: 'text-white',
          glow: '[box-shadow:0_0_130px_20px_theme(colors.purple.500)]',
        },
        featured: true,
        mallLogos: ['/img/mall_ico/wb_big.png'],
        description: '',
        features: {
          en: [
            'Authorized Wildberries analytics service',
            'API-based seller dashboard',
            'Product, category, store and brand analytics',
            'Search query analytics',
            'Automated ad auction bidder',
            'Review autoreplier',
            'SEO optimization and description generation using AI',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            '授权的Wildberries分析服务',
            '基于API的卖家仪表板',
            '产品、类别、商店和品牌分析',
            '搜索查询分析',
            '自动化广告拍卖出价管理',
            '评论自动回复',
            '使用人工智能的SEO优化和内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 748, discountedPrice: 748 },
          90: { price: 2244, discountedPrice: 1884 },
          180: { price: 4488, discountedPrice: 3348 },
          360: { price: 8976, discountedPrice: 5376 },
        },
      },
      {
        mall: 'beru',
        theme: {
          bg: 'bg-transparent',
          fg: 'text-white',
          glow: '',
        },
        mallLogos: ['/img/mall_ico/beru_white_big.png'],
        description: '',
        features: {
          en: [
            'Official Yandex Market partner',
            'Product, category, store and brand analytics',
            'AI description generation',
            'Unlimited analysis period',
            'Unlimited products',
            'Unlimited exports to Excel',
          ],
          zh: [
            'Yandex Market官方合作伙伴',
            '产品、类别、商店和品牌分析',
            '人工智能内容生成',
            '分析时间不受限制',
            '无限产品',
            '导出至 Excel，最多 10,000 行',
          ],
        },
        pricing: {
          30: { price: 668, discountedPrice: 668 },
          90: { price: 2004, discountedPrice: 1674 },
          180: { price: 4008, discountedPrice: 2988 },
          360: { price: 8016, discountedPrice: 4776 },
        },
      },
    ],
  },
}
