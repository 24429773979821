import { PlusCircleIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import Container from './Container'
import Heading from './Heading'
import * as Accordion from '@radix-ui/react-accordion'
import { HostType } from 'configs/theme'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'

const title: Partial<Record<HostType, Partial<Record<string, string>>>> = {
  sellmonitor: { ru: 'Вопросы и ответы' },
  uzum: { uz: 'Savollar va javoblar', ru: 'Вопросы и ответы' },
  sellscreen: { en: 'Frequently asked questions', zh: '常见问题' },
}

const faqsByHost: Partial<
  Record<HostType, Partial<Record<string, { question: string; answer: JSX.Element }[]>>>
> = {
  sellmonitor: {
    ru: [
      {
        question: 'Чем мне поможет ваш сервис? Сможет ли он окупить инвестиции?',
        answer: (
          <>
            <p>Наш сервис поможет вам несколькими способами.</p>
            <p>
              С помощью анализа ниши вы сможете подобрать такую категорию, где конкуренция не
              слишком высокая, а популярность и продажи на хорошем уровне.
            </p>
            <p>
              Анализ конкурентов поможет следить за другими продавцами в вашей нише и выявлять
              выигрышные стратегии.
            </p>
            <p>
              Личный кабинет Wildberries и Ozon позволит мониторить состояние бизнеса, выявлять
              убыточные товары и избавляться от них.
            </p>
            <p>
              Умные инструменты помогут управлять рекламой, рассчитать поставки и оптимизировать
              контент в карточке товара.
            </p>
            <p>
              Мы попросили наших разработчиков подсчитать, насколько увеличивается выручка
              пользователей, подключивших к сервису API маркетплейса. В среднем она увеличивается на
              44% за 3 месяца использования сервиса (по сравнению с периодом 3 месяца до начала
              использования).
            </p>
          </>
        ),
      },
      {
        question: 'Откуда вы берёте данные? Насколько они точные?',
        answer: (
          <>
            <p>
              Данные по вашему бизнесу мы берём из API маркетплейса. Такие данные очень точны — мы
              даже можем определить, если маркетплейс ошибся в расчётах или что-то вам не доплатил.
            </p>
            <p>
              Данные для внешней аналитики мы собираем как из открытых источников, так и
              рассчитываем с помощью продвинутых алгоритмов. Можно считать, что точность таких
              данных примерно 95%. Они могут быть неточны, например, если продавец предоставляет
              маркетплейсу неверные сведения по остаткам на складе. Мы постоянно совершенствуем наши
              алгоритмы, чтобы определять и избавляться от таких аномальных данных.
            </p>
          </>
        ),
      },
      {
        question: 'Какие маркетплейсы поддерживаются?',
        answer: (
          <>
            <p>
              На данный момент мы предлагаем Wildberries, Ozon, Яндекс Маркет, Мегамаркет, lamoda и
              Uzum Market. Обратите внимание, что для разных маркетплейсов поддерживаются разные
              инструменты — полный список можно посмотреть в разделе{' '}
              <a className="text-primary-600" href="#pricing">
                Тарифы
              </a>{' '}
              или в процессе оформления подписки.
            </p>
            <p>
              По запросу вы можете получить доступ и к другим маркетплейсам — у нас их более 20
              штук. Уточните в службе поддержки, какие из интересующих вас маркетплейсов сейчас
              можно подключить.
            </p>
          </>
        ),
      },
      {
        question:
          'Ваш сервис выглядит круто, но я боюсь, что у меня не получится в нём разобраться',
        answer: (
          <>
            <p>
              Не волнуйтесь — мы всегда на связи и готовы помочь вам и ответить на все вопросы.
              Видите кнопку в углу? С её помощью можно связаться с нашим менеджером. Эта кнопка есть
              на всех страницах сайта, так что вы сможете задать вопрос в любой момент и совершенно
              точно никогда не застрянете.
            </p>
            <p>
              Помимо этого, у нас есть раздел «Помощь», где подробно рассказывается, как
              пользоваться сервисом. Просто посмотрите видео и повторяйте за нашими аналитиками, и у
              вас всё получится.
            </p>
            <p>
              Готовы также провести для вас индивидуальное обучение — напишите нам, если это
              требуется.
            </p>
          </>
        ),
      },
      {
        question: 'Не хочу платить за аналитику, пока не начну зарабатывать',
        answer: (
          <>
            <p>
              Если вы уже решили торговать на маркетплейсах, вам не обойтись без аналитики. Особенно
              важна аналитика на первых этапах — вам нужно выбрать, какую нишу вы хотите занять,
              какие товары принесут прибыль. Если вы попробуете зайти на рынок вслепую, не
              исключено, что выбранная стратегия не сработает. Наш совет — лучше не экономить на
              таких вещах и пользоваться любыми преимуществами, которые вы можете получить.
            </p>
          </>
        ),
      },
      {
        question: 'Я представляю крупную компанию, можете ли вы что-то сделать специально для нас?',
        answer: (
          <>
            <p>
              С крупными брендами мы работаем через наш сервис{' '}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
                href="https://sellematics.com/ru/"
              >
                Sellematics
              </a>
              . Он предоставляет подробную аналитику по брендам, корпоративные аккаунты, а также
              возможность кастомных выгрузок и исследований по нашим данным. В Sellematics сейчас
              доступно более 20 российских маркетплейсов и ритейлеров — актуальный список можно
              посмотреть на сайте.
            </p>
          </>
        ),
      },
    ],
  },
  uzum: {
    uz: [
      {
        question: 'Xizmatingiz menga qanday yordam beradi? Investitsiyalarni oqlaydimi?',
        answer: (
          <>
            <p>Xizmatimiz sizga bir necha yo‘llar bilan yordam beradi.</p>
            <p>
              Nisha tahlili yordamida siz raqobat unchalik yuqori bo‘lmagan va savdo yaxshi darajada
              bo‘lgan toifani tanlashingiz mumkin.
            </p>
            <p>
              Raqobatchilar tahlili sizga muvaffaqiyatli strategiyalarni topishga va ularni
              takrorlashga yordam beradi, shuningdek, boshqa sotuvchilar qiladigan xatolardan
              qochishga yordam beradi.
            </p>
            <p>
              Wildberries va Ozon shaxsiy kabineti zararli mahsulotlarni aniqlash va ulardan
              qutulish, shuningdek, yetkazib berishni optimallashtirish imkonini beradi.
            </p>
            <p>
              O‘rtacha, mijozlarimiz xizmatdan foydalanishning 3 oyi davomida daromadlarini 44% ga
              oshiradilar (xizmatdan foydalanish boshlanishidan oldingi 3 oy bilan solishtirganda).
            </p>
          </>
        ),
      },
      {
        question: 'Ma’lumotlarni qayerdan olasiz? Ular qanchalik aniq?',
        answer: (
          <>
            <p>
              Biz sizning biznesingiz bo‘yicha ma’lumotlarni marketplace API dan olamiz. Bunday
              ma’lumotlar juda aniq — hatto marketplace hisob-kitoblarda xato qilgan yoki sizga
              to‘lamagan narsalarni aniqlashimiz mumkin.
            </p>
            <p>
              Tashqi tahlil uchun ma’lumotlarni ochiq manbalardan yig‘amiz va ilg‘or algoritmlar
              yordamida hisoblaymiz. Bunday ma’lumotlarning aniqligi taxminan 95% deb hisoblash
              mumkin. Ular noaniq bo‘lishi mumkin, masalan, agar sotuvchi marketplace ga omborda
              qolgan mahsulotlar haqida noto‘g‘ri ma’lumot bersa. Biz bunday anomaliyalarni aniqlash
              va ulardan qutulish uchun algoritmlarimizni doimiy ravishda takomillashtiramiz.
            </p>
          </>
        ),
      },
      {
        question: 'Qaysi marketpleyslar qo‘llab-quvvatlanadi?',
        answer: (
          <>
            <p>
              Hozirgi vaqtda biz Uzum Market, Wildberries va Ozonni taklif qilamiz. E’tibor bering,
              har bir marketpleys uchun turli vositalar qo‘llab-quvvatlanadi — to‘liq ro‘yxatni
              quyidagi bo‘limda{' '}
              <a className="text-primary-600" href="#pricing">
                Tariflar
              </a>{' '}
              yoki ba’zi marketpleyslar uchun obuna olish jarayonida ko‘rishingiz mumkin.
            </p>
            <p>
              So‘rov bo‘yicha siz boshqa marketpleyslarga ham kirish imkoniyatiga ega bo‘lishingiz
              mumkin — bizda 20 dan ortiq marketpleys mavjud. Sizning qiziqtirgan marketpleyslardan
              qaysi biri hozirgi paytda Sellmonitorda mavjudligini xizmat ko‘rsatish xizmati orqali
              aniqlashingiz mumkin.
            </p>
          </>
        ),
      },
      {
        question: 'Xizmatingiz juda zo‘r ko‘rinadi, lekin men uni tushunolmayman deb qo‘rqaman',
        answer: (
          <>
            <p>
              Xavotir olmang — biz har doim aloqadamiz va sizga yordam berishga va barcha savollarga
              javob berishga tayyormiz. Burchakdagi tugmani ko‘ryapsizmi? Uning yordamida bizning
              menejerimiz bilan bog‘lanishingiz mumkin. Bu tugma saytning barcha sahifalarida
              mavjud, shuning uchun siz istalgan vaqtda savol berishingiz mumkin va hech qachon
              qiyinchilikka duch kelmaysiz.
            </p>
            <p>
              Bundan tashqari, bizda xizmatdan qanday foydalanish haqida batafsil ma’lumot berilgan
              «Yordam» bo‘limi mavjud. Shunchaki videoni tomosha qiling va bizning
              tahlilchilarimizni takrorlang, va sizda hammasi yaxshi bo‘ladi.
            </p>
          </>
        ),
      },
      {
        question: 'Analitika uchun to‘lashni xohlamayman, daromad olishni boshlamagunimcha',
        answer: (
          <>
            <p>
              Agar siz allaqachon marketplace larda savdo qilishga qaror qilgan bo‘lsangiz,
              analitikasiz qilolmaysiz. Ayniqsa, dastlabki bosqichlarda analitika juda muhim — siz
              qaysi nishani egallamoqchi ekanligingizni, qaysi mahsulotlar foyda keltirishi
              mumkinligini tanlashingiz kerak. Agar siz marketpleysga ko‘r-ko‘rona kirishga harakat
              qilsangiz, tanlangan strategiya ishlamasligi mumkin. Bizning maslahatimiz — bunday
              narsalarga tejashdan ko‘ra, qo‘lga kiritishingiz mumkin bo‘lgan har qanday
              afzalliklardan foydalanish yaxshiroqdir.
            </p>
          </>
        ),
      },
      {
        question:
          'Men yirik kompaniyani ifodalayman, siz biz uchun maxsus biror narsa qila olasizmi?',
        answer: (
          <>
            <p>
              Yirik brendlar bilan biz{' '}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
                href="https://sellematics.com/ru/"
              >
                Sellematics
              </a>
              xizmati orqali ishlaymiz. U brendlar bo‘yicha batafsil tahlil, korporativ hisoblar,
              shuningdek, bizning ma’lumotlarimiz bo‘yicha maxsus yuklamalar va tadqiqotlar
              imkoniyatini taqdim etadi. Hozirda Sellematics da 20 dan ortiq rus marketplace lari va
              chakana sotuvchilari mavjud — dolzarb ro‘yxatni saytimizda ko‘rishingiz mumkin.
            </p>
          </>
        ),
      },
    ],
    ru: [
      {
        question: 'Чем мне поможет ваш сервис? Сможет ли он окупить инвестиции?',
        answer: (
          <>
            <p>Наш сервис поможет вам несколькими способами.</p>
            <p>
              С помощью анализа ниши вы сможете подобрать такую категорию, где конкуренция не
              слишком высокая, а популярность и продажи на хорошем уровне.
            </p>
            <p>
              Анализ конкурентов поможет выявить выигрышные стратегии и скопировать их, а также не
              допустить ошибок, которые совершают другие.
            </p>
            <p>
              Личный кабинет Wildberries и Ozon позволит выявить убыточные товары и избавиться от
              них, а также оптимизировать поставки.
            </p>
            <p>
              В среднем наши клиенты увеличивают выручку на 44% за 3 месяца использования сервиса
              (по сравнению с периодом 3 месяца до начала использования сервиса).
            </p>
          </>
        ),
      },
      {
        question: 'Откуда вы берёте данные? Насколько они точные?',
        answer: (
          <>
            <p>
              Данные по вашему бизнесу мы берём из API маркетплейса. Такие данные очень точны — мы
              даже можем определить, если маркетплейс ошибся в расчётах или что-то вам не доплатил.
            </p>
            <p>
              Данные для внешней аналитики мы собираем как из открытых источников, так и
              рассчитываем с помощью продвинутых алгоритмов. Можно считать, что точность таких
              данных примерно 95%. Они могут быть неточны, например, если продавец предоставляет
              маркетплейсу неверные сведения по остаткам на складе. Мы постоянно совершенствуем наши
              алгоритмы, чтобы определять и избавляться от таких аномальных данных.
            </p>
          </>
        ),
      },
      {
        question: 'Какие маркетплейсы поддерживаются?',
        answer: (
          <>
            <p>
              На данный момент мы предлагаем Uzum Market, Wildberries и Ozon. Обратите внимание, что
              для разных маркетплейсов поддерживаются разные инструменты — полный список можно
              посмотреть в разделе{' '}
              <a className="text-primary-600" href="#pricing">
                Тарифы
              </a>{' '}
              или в процессе оформления подписки для некоторых маркетплейсов.
            </p>
            <p>
              По запросу вы можете получить доступ и к другим маркетплейсам — у нас их более 20
              штук. Уточните в службе поддержки, какие из интересующих вас маркетплейсов сейчас
              доступны на Sellmonitor.
            </p>
          </>
        ),
      },
      {
        question:
          'Ваш сервис выглядит круто, но я боюсь, что у меня не получится в нём разобраться',
        answer: (
          <>
            <p>
              Не волнуйтесь — мы всегда на связи и готовы помочь вам и ответить на все вопросы.
              Видите кнопку в углу? С её помощью можно связаться с нашим менеджером. Эта кнопка есть
              на всех страницах сайта, так что вы сможете задать вопрос в любой момент и совершенно
              точно никогда не застрянете.
            </p>
            <p>
              Помимо этого, у нас есть раздел «Помощь», где подробно рассказывается, как
              пользоваться сервисом. Просто посмотрите видео и повторяйте за нашими аналитиками, и у
              вас все получится.
            </p>
          </>
        ),
      },
      {
        question: 'Не хочу платить за аналитику, пока не начну зарабатывать',
        answer: (
          <>
            <p>
              Если вы уже решили торговать на маркетплейсах, вам не обойтись без аналитики. Особенно
              важна аналитика на первых этапах — вам нужно выбрать, какую нишу вы хотите занять,
              какие товары принесут прибыль. Если вы попробуете зайти на рынок вслепую, не
              исключено, что выбранная стратегия не сработает. Наш совет — лучше не экономить на
              таких вещах и пользоваться любыми преимуществами, которые вы можете получить.
            </p>
          </>
        ),
      },
      {
        question: 'Я представляю крупную компанию, можете ли вы что-то сделать специально для нас?',
        answer: (
          <>
            <p>
              С крупными брендами мы работаем через наш сервис{' '}
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
                href="https://sellematics.com/ru/"
              >
                Sellematics
              </a>
              . Он предоставляет подробную аналитику по брендам, корпоративные аккаунты, а также
              возможность кастомных выгрузок и исследований по нашим данным. В Sellematics сейчас
              доступно более 20 российских маркетплейсов и ритейлеров — актуальный список можно
              посмотреть на сайте.
            </p>
          </>
        ),
      },
    ],
  },
  sellscreen: {
    en: [
      {
        question: 'How will your service help me? Will it justify the investment?',
        answer: (
          <>
            <p>Our service can help you in several ways.</p>
            <p>
              Using niche analytics, you can find a category where the competition is not too high
              and the sales are at a good level.
            </p>
            <p>
              Competitor analysis will help you find winning strategies and replicate them, as well
              as avoid making mistakes other sellers make.
            </p>
            <p>
              In general, we found that our clients increase their revenue by 44% over a 3 month
              period after using our service.
            </p>
          </>
        ),
      },
      {
        question: 'Where does the data come from? How accurate is it?',
        answer: (
          <>
            <p>
              Some of the data comes from publicly available sources, some of it we calculate using
              advanced algorithms. The data may be inaccurate if the store reports incorrect stocks
              to the marketplace, for example.
            </p>
          </>
        ),
      },
      {
        question: 'Which marketplaces are supported?',
        answer: (
          <>
            <p>
              Currently, we offer Wildberries, Ozon, Yandex Market, Megamarket, Lamoda, DNS, and
              Citilink. Please note that different tools are supported for different marketplaces —
              the full list can be viewed in the{' '}
              <a className="text-primary-600" href="#pricing">
                Pricing
              </a>{' '}
              section or during the subscription process for some marketplaces.
            </p>
            <p>
              Upon request, you can also gain access to other marketplaces — we have more than 20
              available. Please check with our support service which of the marketplaces you are
              interested in are currently available on Sellscreen.
            </p>
          </>
        ),
      },
    ],
    zh: [
      {
        question: 'Sellscreen软件对我有什么帮助？是否值购买订阅',
        answer: (
          <>
            <p>我们的服务可以在多个方面为您提供帮助。</p>
            <p>利用利基分析，您可以找到一个竞争不太激烈、销售情况良好的类别。</p>
            <p>竞争对手分析将帮助您找到制胜策略并加以复制，同时避免犯其他卖家所犯的错误。</p>
            <p>一般来说，我们发现客户在使用我们的服务后，3 个月内收入会增加 44%。</p>
          </>
        ),
      },
      {
        question: '数据从何而来？准确度如何？',
        answer: (
          <>
            <p>
              部分数据来自公开来源，部分数据是我们通过高级算法计算得出的。例如，如果商店向市场报告的库存不正确，数据就可能不准确。
            </p>
          </>
        ),
      },
      {
        question: '支持哪些电商平台？',
        answer: (
          <>
            <p>
              目前，我们提供Wildberries、Ozon、Yandex
              Market、Megamarket、Lamoda、DNS和Citilink。请注意，不同市场支持不同的工具 —
              完整列表可以在&quot;资费&quot;部分查看，或在为某些市场订阅过程中查看。
            </p>
            <p>
              根据请求，您还可以获得其他市场的访问权限 —
              我们有超过20个市场。请向我们的支持服务询问您感兴趣的市场中哪些目前在Sellscreen上可用。
            </p>
          </>
        ),
      },
    ],
  },
  sellematics: {
    en: [
      {
        question: 'How will your service help me? Will it justify the investment?',
        answer: (
          <>
            <p>Our service can help you in several ways.</p>
            <p>
              Using niche analytics, you can find a category where the competition is not too high
              and the sales are at a good level.
            </p>
            <p>
              Competitor analysis will help you find winning strategies and replicate them, as well
              as avoid making mistakes other sellers make.
            </p>
            <p>
              In general, we found that our clients increase their revenue by 44% over a 3 month
              period after using our service.
            </p>
          </>
        ),
      },
      {
        question: 'Where does the data come from? How accurate is it?',
        answer: (
          <>
            <p>
              Some of the data comes from publicly available sources, some of it we calculate using
              advanced algorithms. The data may be inaccurate if the store reports incorrect stocks
              to the marketplace, for example.
            </p>
          </>
        ),
      },
      {
        question: 'Which marketplaces are supported?',
        answer: (
          <>
            <p>
              We currently offer Amazon.ae, Noon UAE and Noon Saudi Arabia, and Shopify stores with
              AED as currency. More marketplaces will become available in the future. Feel free to
              contact us with your suggestions — what should we add next?
            </p>
          </>
        ),
      },
    ],
  },
}

const Faq: FC = () => {
  const ctx = useUserContext()
  const { t, lang } = useIntl()
  const faqs = faqsByHost[ctx.theme.hostType]![lang]!

  return (
    <Container className="relative bg-gray-50">
      <a id="faq" className="absolute -translate-y-16" />
      <div className="mx-auto max-w-3xl">
        <Heading tagline={title[ctx.theme.hostType]![lang]!} />
        <Accordion.Root type="single" collapsible asChild>
          <dl className="mt-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Accordion.Item value={faq.question} key={faq.question} className="">
                <Accordion.Header>
                  <dt className="text-lg">
                    <Accordion.Trigger asChild>
                      <button className="flex items-start justify-between w-full py-6 text-left text-gray-600 [&[data-state=open]_svg]:-rotate-45">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="flex items-center ml-6 h-7 shrink-0">
                          <PlusCircleIcon
                            className={c`w-6 h-6 transition-transform shrink-0`}
                            aria-hidden="true"
                          />
                        </span>
                      </button>
                    </Accordion.Trigger>
                  </dt>
                </Accordion.Header>
                <Accordion.Content className="overflow-hidden data-[state=open]:animate-accordion-down data-[state=closed]:animate-accordion-up">
                  <dd className="pr-12 mb-6 space-y-2 text-base text-gray-500">{faq.answer}</dd>
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </dl>
        </Accordion.Root>
        <div />
      </div>
    </Container>
  )
}

export default Faq
